import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Data from "./pages/data";
import Header from "./layout/header";
import Footer from "./layout/footer";

function App() {

  return (
    <div className="app">
    <Header />
       <main className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/data" element={<Data />} />
            </Routes>
          </main>
    <Footer />
    </div>
  );
}

export default App;
