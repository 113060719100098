import React from "react";

const DataComplete = (data) => {
  const datos = data.data.data.slice(1);

  const copyToClipboard = () => {
    const textToCopy = datos
      .map((event) => {
        const firstValue = event[1] !== undefined ? event[1] : "";
        const secondValue = event[2] !== undefined ? event[2] : "";
        const thirdValue = event[6] !== undefined ? event[6] : "";
        const fourthValue = event[0] !== undefined ? event[0].replace(/\n/g, ' ') : '';
        const fifthValue = event[5] !== undefined ? event[5] : "";

        return `Fecha: ${firstValue} Hora: ${secondValue} Lugar: ${thirdValue} Nombre de la Actividad: ${fourthValue} Descripción: ${fifthValue}`;
      })
      .join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => alert("¡Contenido copiado al portapapeles!"))
      .catch((err) => console.error("Error al copiar al portapapeles", err));
  };


  const reload = () => {
    window.location.reload(true);
  };

  return (
    <div className="data-container">
      <button className="btn-copy" onClick={copyToClipboard}>
        Copiar al portapapeles
      </button>
      <button className="reload" onClick={reload}>
        Limpiar
      </button>
      {datos.map((event, index) => (
        <div key={index}>
          <p>
            <b>Fecha: </b>
            {event[1]}
            <b> Hora: </b>
            {event[2]}
            <b> Lugar: </b>
            {event[6]}
            <b> Nombre de la Actividad: </b>
            {event[0]}
            <b> Descripción: </b>
            {event[5]}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DataComplete;
