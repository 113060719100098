import React, { useState } from "react";
import { useCSVReader } from "react-papaparse";
import Data from "./data";
import DataComplete from "./dataComplete";

const Home = () => {
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();

  const styles = {
    csvReader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    },
    acceptedFile: {
      border: "1px solid #ccc",
      height: 45,
      lineHeight: 2.5,
      paddingLeft: 10,
      width: "80%",
    },
    progressBarBackgroundColor: {
      backgroundColor: "red",
    },
  };

  const { CSVReader } = useCSVReader();

  return (
    <div>
      <h2>Conversor CSV a formato legible</h2>
      <div className="converter">
        <div className="clean-data">
          <label>Programación (sin descripción de las actividades)</label>
          <CSVReader
            style={styles.CSVReader}
            onUploadAccepted={(results) => {
              setData1(results);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }) => (
              <>
                <div style={styles.csvReader}>
                  <button
                    type="button"
                    {...getRootProps()}
                    className="browse-file"
                  >
                    Buscar Archivo
                  </button>
                  <div style={styles.acceptedFile}>
                    {acceptedFile && acceptedFile.name}
                  </div>
                  <button {...getRemoveFileProps()} className="remove">
                    Quitar
                  </button>
                </div>
                <ProgressBar style={styles.progressBarBackgroundColor} />
              </>
            )}
          </CSVReader>
        </div>
        <div className="complete-data">
          <label>Programación (completa)</label>
          <CSVReader
            style={styles.CSVReader}
            onUploadAccepted={(results) => {
              setData2(results);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }) => (
              <>
                <div style={styles.csvReader}>
                  <button
                    type="button"
                    {...getRootProps()}
                    className="browse-file"
                  >
                    Buscar Archivo
                  </button>
                  <div style={styles.acceptedFile}>
                    {acceptedFile && acceptedFile.name}
                  </div>
                  <button {...getRemoveFileProps()} className="remove">
                    Quitar
                  </button>
                </div>
                <ProgressBar style={styles.progressBarBackgroundColor} />
              </>
            )}
          </CSVReader>
        </div>
      </div>
        {data1 ? <Data data={data1} /> : ""}
        {data2 ? <DataComplete data={data2} /> : ""}
    </div>
  );
};

export default Home;
