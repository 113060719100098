import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="info">
        <div>Dirección General de Planificación Cultural</div>
        <div>
          <img
            alt="Logo Muni"
            src="https://www.rosario.gob.ar/inicio/sites/default/themes/custom/pic_theme/assets/images/logo_mobile.svg"
          />
        </div>
      </div>
      <div className="copy">
        <p>
          Aplicación desarrollada por{" "}
          <a href="https://libraweb.com.ar">Libra - Soluciones web</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
